.arrow {
  width: 100%;
  height: 2px;
}

.arrow-x {
  transform: translate(-50%, -50%);
}

.arrow-y {
  transform: translate(-50%, -50%) rotate(90deg);
}