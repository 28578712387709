.decor-brand:before {
  content: '?';
  position: absolute;
  text-align: center;
  vertical-align: middle;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  background-color: #ff6900;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 4rem;
  width: 6rem;
  height: 6rem;
  border: 4px solid #FFFFFF;
  border-radius: 1.5rem;
}